.home {
	.home__apps {
		margin-block-start: var(--fluid-space-36);
		display: flex;
		flex-direction: column;
		gap: var(--fluid-space-16);

		article {
			max-width: var(--screen-sm);
		}

		@media screen and (max-width: 768px) {
			align-items: center;

			article {
				flex-direction: column;
				text-align: center;
				max-width: calc(var(--screen-sm) / 2);

				nav {
					justify-content: center;
				}
			}
		}
	}

	.home__humans {
		margin-block-start: var(--fluid-space-48);

		.home__humans__profiles {
			margin-block-start: var(--fluid-space-24);
			display: flex;
			gap: var(--fluid-space-16);
		}

		.home__humans__profile__picture {
			width: var(--space-24);
			height: var(--space-24);
			border-radius: var(--border-radius-full);
		}

		@media screen and (max-width: 768px) {
			 .home__humans__profiles {
				 flex-direction: column;
			 }
		}
	}

	.home__services {
		margin-block-start: var(--fluid-space-48);
	}

	.home__connect {
		margin-block-start: var(--fluid-space-48);
	}
}
