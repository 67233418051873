.star_line {
	height: var(--space-6);
	margin-block-end: var(--space-12);
	position: relative;

	.quote {
		margin: 0;
		position: absolute;
		left: 0;
		opacity: 1;
		font-style: italic;

		&.quote--ready {
			transition: opacity, 1s cubic-bezier(0.4, 0, 0.2, 1);
		}

		&.quote--hidden {
			opacity: 0;
		}

		.stars {
			font-style: normal;
			color: rgba(var(--rgb-yellow));
		}
	}

	@media screen and (max-width: 768px) {
		font-size: var(--font-sm);
		line-height: var(--line-height-sm);
	}
}
