.grid {
	display: grid;
	grid-template-columns: 1fr;
	column-gap: var(--space-8);
	row-gap: var(--fluid-space-36);

	@media screen and (min-width: 1024px) {
		grid-template-columns: repeat(12, 1fr);

		.grid__content {
			grid-column: span 9;

			&.grid__content--large {
				grid-column: span 11;
			}

			&.grid__content--full {
				grid-column: span 12;
			}
		}

		.grid__aside {
			grid-column: span 6;
			height: min-content;
		}
	}

	@media screen and (min-width: 1280px) {
		.grid__content {
			grid-column: span 7;

			&.grid__content--large {
				grid-column: span 9;
			}

			&.grid__content--full {
				grid-column: span 12;
			}
		}

		.grid__aside {
			grid-column: 9 / span 4;
			position: sticky;
			top: calc(65px + var(--space-8));
		}
	}
}

:has(#secondary_header) .grid {
	@media screen and (min-width: 1280px) {
		.grid__aside {
			grid-column: 9 / span 4;
			position: sticky;
			top: calc(110px + var(--space-8));
		}
	}
}
