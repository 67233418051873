.update {
	padding-block-end: var(--fluid-space-36);
	border-inline-start: dashed 1px var(--color-ink-tertiary);
	align-items: start;
	row-gap: var(--fluid-space-16);

	&:last-of-type {
		padding-block-end: 0;
	}

	.update__aside {
		padding-inline-start: var(--space-6);
		position: relative;

		&::before {
			content: "";
			display: block;
			position: absolute;
			right: 100%;
			transform: translateX(calc(50% - 1px));
			width: var(--space-3);
			height: var(--space-3);
			background-color: var(--color-white);
			border-radius: var(--border-radius-full);
		}
	}

	.update__content {
		padding-inline-start: var(--space-6);

		.figure {
			margin-block-start: 0;
		}
	}

	@media screen and (min-width: 1024px) {
		.update__aside {
			grid-column: span 4;
			position: sticky;
			top: calc(65px + var(--space-8));
		}

		.update__content {
			grid-column: 6 / span 7;
			padding-inline-start: 0;
		}
	}

	.update__date {
		font-size: var(--font-sm);
		line-height: var(--font-sm);
		color: var(--color-ink-secondary);
		margin-block-end: var(--space-1);

		@media screen and (max-width: 768px) {
			font-size: var(--font-xs);
			line-height: var(--font-xs);
		}
	}

	.update__title {
		font-size: var(--font-4xl);
		line-height: var(--line-height-4xl);

		@media screen and (min-width: 768px) {
			line-height: 1.375;
		}
	}
}

:has(#secondary_header) .update {
	@media screen and (min-width: 1024px) {
		.update__aside {
			top: calc(110px + var(--space-8));
		}
	}
}
