/* Text */
.txt-xs {
	font-size: var(--font-xs);
	line-height: var(--line-height-xs);
}
.txt-sm {
	font-size: var(--font-sm);
	line-height: var(--line-height-sm);
}
.txt-lg {
	font-size: var(--font-lg);
	line-height: var(--line-height-lg);
}
.txt-xl {
	font-size: var(--font-xl);
	line-height: var(--line-height-xl);
}
.txt-2xl {
	font-size: var(--font-2xl);
	line-height: var(--line-height-2xl);
}
.txt-3xl {
	font-size: var(--font-3xl);
	line-height: var(--line-height-3xl);
}
.txt-4xl {
	font-size: var(--font-4xl);
	line-height: var(--line-height-4xl);

	@media screen and (min-width: 768px) {
		line-height: 1.375;
	}
}
.txt-5xl {
	font-size: var(--font-5xl);
	line-height: var(--line-height-5xl);
}
.txt-6xl {
	font-size: var(--font-6xl);
	line-height: var(--line-height-6xl);

	@media screen and (min-width: 768px) {
		line-height: 1.1;
	}
}
.txt-7xl {
	font-size: var(--font-7xl);
	line-height: var(--line-height-7xl);

	@media screen and (min-width: 768px) {
		line-height: 1.15;
		letter-spacing: -0.025em;
	}
}

.txt-thin { font-weight: 100; }
.txt-extralight { font-weight: 200; }
.txt-light { font-weight: 300; }
.txt-normal { font-weight: 400; }
.txt-medium { font-weight: 500; }
.txt-semibold { font-weight: 600; }
.txt-bold { font-weight: 700; }
.txt-extrabold { font-weight: 800; }
.txt-black { font-weight: 900; }

.txt-ink { color: var(--color-ink); }
.txt-ink-secondary { color: var(--color-ink-secondary); }
.txt-ink-tertiary { color: var(--color-ink-tertiary); }
.txt-ink-quaternary { color: var(--color-ink-quaternary); }
.txt-ink-quinary { color: var(--color-ink-quinary); }
.txt-inverted { color: var(--color-ink-inverted); }

.txt-blue { color: rgb(var(--rgb-blue)) }
.txt-brown { color: rgb(var(--rgb-brown)) }
.txt-cyan { color: rgb(var(--rgb-cyan)) }
.txt-gray { color: rgb(var(--rgb-gray)) }
.txt-green { color: rgb(var(--rgb-green)) }
.txt-indigo { color: rgb(var(--rgb-indigo)) }
.txt-mint { color: rgb(var(--rgb-mint)) }
.txt-orange { color: rgb(var(--rgb-orange)) }
.txt-pink { color: rgb(var(--rgb-pink)) }
.txt-purple { color: rgb(var(--rgb-purple)) }
.txt-red { color: rgb(var(--rgb-red)) }
.txt-teal { color: rgb(var(--rgb-teal)) }
.txt-yellow { color: rgb(var(--rgb-yellow)) }

.txt-center { text-align: center; }

.line-clamp-1 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	line-clamp: 1;
}

/* Background */
.bg { background-color: var(--color-bg); }
.bg-secondary { background-color: var(--color-bg-secondary); }
.bg-tertiary { background-color: var(--color-bg-tertiary); }
.bg-quaternary { background-color: var(--color-bg-quaternary); }
.bg-quinary { background-color: var(--color-bg-quinary); }

/* Flexbox and Grid */
.justify-end { justify-content: end; }
.justify-start { justify-content: start; }
.justify-center { justify-content: center; }
.justify-space-between { justify-content: space-between; }

.align-center { align-items: center; }
.align-start { align-items: start; }
.align-end { align-items: end; }
.align-baseline { align-items: baseline; }

.align-self-start { align-self: start; }

.contain { contain: inline-size; }

.flex { display: flex; }
.flex-inline { display: inline-flex; }
.flex-column { flex-direction: column; }
.flex-wrap { flex-wrap: wrap; }

.flex-item-grow { flex-grow: 1; }
.flex-item-shrink { flex-shrink: 1; }
.flex-item-no-shrink { flex-shrink: 0; }
.flex-item-justify-start { margin-inline-end: auto; }
.flex-item-justify-end { margin-inline-start: auto; }

.gap-05 { gap: var(--space-05); }
.gap-1 { gap: var(--space-1); }
.gap-2 { gap: var(--space-2); }
.gap-4 { gap: var(--space-4); }
.gap-6 { gap: var(--space-6); }
.gap-8 { gap: var(--space-8); }
.gap-12 { gap: var(--space-12); }

/* Sizing */
.size-5 { width: var(--space-5); height: var(--space-5); }
.size-6 { width: var(--space-6); height: var(--space-6); }
.size-8 { width: var(--space-8); height: var(--space-8); }
.size-12 { width: var(--space-12); height: var(--space-12); }
.size-16 { width: var(--space-16); height: var(--space-16); }
.size-24 { width: var(--space-24); height: var(--space-24); }
.size-36 { width: var(--space-36); height: var(--space-36); }
.size-48 { width: var(--space-48); height: var(--space-48); }

.fluid-size-12 { width: var(--fluid-space-12); height: var(--fluid-space-12); }

.width-auto { width: auto; }

.full-width { inline-size: 100%; }
.min-width { min-inline-size: 0; }
.half-width { inline-size: 50%; }
.max-width { max-inline-size: 100%; }
.min-content { inline-size: min-content; }
.max-content { inline-size: max-content; }
.max-inline-size { max-inline-size: 100%; }

/* Position */
.position-relative { position: relative; }
.position-sticky { position: sticky; }

/* Margins */
.margin-none { margin: 0; }
.margin-block-fluid-4 { margin-block: var(--fluid-space-4) }
.margin-block-fluid-8 { margin-block: var(--fluid-space-8) }
.margin-block-fluid-16 { margin-block: var(--fluid-space-16) }
.margin-block-fluid-24 { margin-block: var(--fluid-space-24) }

.margin-block-3 { margin-block: var(--space-3) }
.margin-block-8 { margin-block: var(--space-8) }
.margin-block-16 { margin-block: var(--space-16) }

/* Visibility */
[hidden], .hidden { display: none; }
[contents], .contents { display: contents; }

.desktop-only {
	@media screen and (max-width: 768px) {
		display: none;
	}
}

.mobile-only {
	@media screen and (min-width: 768px) {
		display: none;
	}
}

/* Other */
.no-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}
