.markdown {
	h1,
	h2,
	h3 {
		scroll-margin-block: calc(65px + var(--space-8));
	}
}

:has(#secondary_header) .markdown {
	h1,
	h2,
	h3 {
		scroll-margin-block: calc(110px + var(--space-8));
	}
}

.markdown {
	/* NOTE: Headings and texts */
	.big_text {
		font-size: var(--font-7xl);
		line-height: var(--line-height-7xl);

		@media screen and (min-width: 768px) {
			line-height: 1.15;
			letter-spacing: -0.025em;
		}
	}

	h1,
	h2,
	h3 {
		color: var(--color-ink);
		/*scroll-margin-block: var(--fluid-space-36);*/

		&.highlighted {
			color: var(--color-ink-secondary);
		}
	}

	h1 {
		font-size: var(--font-6xl);
		line-height: var(--line-height-6xl);
		margin-block-end: var(--fluid-space-16);

		@media screen and (min-width: 768px) {
			line-height: 1.1;
		}
	}

	h2 {
		font-size: var(--font-4xl);
		line-height: var(--line-height-4xl);
		margin-block-start: var(--fluid-space-16);
		margin-block-end: var(--fluid-space-8);

		@media screen and (min-width: 768px) {
			line-height: 1.375;
		}
	}

	h3 {
		font-size: var(--font-2xl);
		line-height: var(--line-height-2xl);
		margin-block-start: var(--fluid-space-8);
		margin-block-end: var(--space-2);
	}

	h4 {
		font-size: var(--font-lg);
		line-height: var(--line-height-lg);
		margin-block-start: var(--fluid-space-8);
		margin-block-end: var(--space-2);
	}

	p {
		font-size: var(--font-2xl);
		line-height: var(--line-height-2xl);
		color: var(--color-ink-secondary);
		margin-block-end: var(--fluid-space-4);
	}

	small {
		font-size: var(--font-base);
		line-height: var(--line-height-base);
		color: var(--color-ink-secondary);
	}

	blockquote {
		margin-inline: calc(-1 * var(--space-8));
		margin-block: var(--fluid-space-8);
		padding: var(--fluid-space-8);
		background-color: var(--color-bg-tertiary);
		border: solid 1px var(--color-ink-tertiary);
		border-radius: var(--border-radius-xl);

		> p:last-of-type {
			margin-block-end: 0;
		}

		@media screen and (max-width: 1536px) {
			margin-inline: 0;
		}
	}

	a {
		color: var(--color-ink);
		text-decoration: underline;
		text-decoration-thickness: 1px;
		text-decoration-color: var(--color-ink-secondary);
		text-underline-offset: 3px;

		@media (any-hover: hover) {
			&:where(:hover, [data-active="true"], .active) {
				color: var(--color-ink-secondary);
			}
		}
	}

	strong {
		font-weight: 400;
		color: var(--color-ink);
	}

	/* NOTE: Lists */
	ul,
	ol {
		font-size: var(--font-2xl);
		line-height: var(--line-height-2xl);
		color: var(--color-ink-secondary);
		list-style-position: outside;
		padding-inline-start: var(--space-6);
		margin-block-end: var(--fluid-space-4);

		li {
			margin-block-end: var(--space-2);

			&:last-of-type {
				margin-block-end: 0;
			}
		}

		li strong {
			color: var(--color-ink);
			font-weight: 400;
		}
	}

	ul {
		list-style-type: disc;
	}

	ol {
		list-style-type: decimal;
	}

	/* NOTE: Table */
	table {
		font-size: var(--font-lg);
		line-height: var(--line-height-lg);
		color: var(--color-ink-secondary);
		margin-block: var(--fluid-space-16);
		table-layout: fixed;
		border-collapse: separate;
		width: 100%;

		tr {
			td,
			th {
				text-align: center;
				padding: var(--space-2);
			}

			th {
				background: var(--color-ink-quaternary);
				font-weight: 600;
				color: var(--color-ink);
			}

			&:nth-child(even) {
				td {
					background: var(--color-ink-quaternary);
				}
			}

			&:nth-child(odd) {
				td {
					background: var(--color-ink-quinary);
				}
			}

			table-check::after {
				content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" /></svg>');
				display: block;
				margin: 0 auto;
				width: var(--space-6);
				height: var(--space-6);
			}

			table-x-mark::after {
				content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" /></svg>');
				display: block;
				margin: 0 auto;
				width: var(--space-6);
				height: var(--space-6);
			}

			@media screen and (max-width: 768px) {
				td,
				th {
					padding: var(--space-1);
				}

				table-check::after {
					width: var(--space-4);
					height: var(--space-4);
				}

				table-x-mark::after {
					width: var(--space-4);
					height: var(--space-4);
				}
			}
		}
	}

	/* NOTE: Other */
	/* TODO: Sistemare HR */
	hr {
		height: var(--fluid-space-16);
		border: none;
	}
}
