.services {
	:where(#application) {
		min-height: 100dvh;
		display: grid;
		grid-template-rows: auto 1fr auto auto;
		grid-template-columns: minmax(0, 1fr);
	}

	:where(#content) {
		margin-block: var(--fluid-space-48);
	}

	:where(#summary) {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: var(--space-4);

		article {
			background-color: var(--color-bg-tertiary);
			padding: var(--space-6);
			border: solid 1px var(--color-ink-tertiary);
			border-radius: var(--border-radius-2xl);
			backdrop-filter: blur(24px);
		}
	}
}
