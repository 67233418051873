.safe-area {
	padding-inline: var(--fluid-space-16);
	/*overflow: clip;*/
}

.layout-margins {
	max-width: 100%;
	margin-inline: auto;

	@media screen and (min-width: 640px) {
		max-width: var(--screen-sm);
	}

	@media screen and (min-width: 768px) {
		max-width: var(--screen-md);
	}

	@media screen and (min-width: 1024px) {
		max-width: var(--screen-lg);
	}

	@media screen and (min-width: 1280px) {
		max-width: var(--screen-xl);
	}

	/*@media screen and (min-width: 1536px) {*/
	/*	max-width: var(--screen-2xl);*/
	/*}*/
}
