.btn {
	display: block;
	padding-inline: var(--space-3);
	padding-block: var(--space-2);
	background-color: var(--btn-color, var(--color-accent));
	border: 0;
	border-radius: var(--border-radius-lg);
	box-shadow: 0 0 0 1px var(--btn-border-color, var(--color-accent));
	font-size: var(--font-base);
	line-height: var(--font-base);
	font-weight: 500;
	color: var(--btn-text-color, var(--color-ink));
	text-align: center;
	transition: none;
	text-decoration: none !important;

	@media (any-hover: hover) {
		&:where(:hover, [data-active="true"], .active) {
			color: var(--btn-text-color, var(--color-ink)) !important;
			background-color: var(--btn-color-hover, var(--color-accent-vibrant));
		}
	}
}

.btn--header {
	font-size: var(--font-xs);
	line-height: var(--line-height-xs);
	padding-inline: var(--space-3);
	padding-block: var(--space-05);
	border-radius: var(--border-radius-md);
}

.btn--secondary {
	--btn-color: transparent;
	--btn-color-hover: var(--color-ink-quaternary);
	--btn-border-color: var(--color-ink-tertiary);
}

.btn--clear {
	display: flex;
	font-size: inherit;
	line-height: inherit;
	padding: 0;
	margin: 0;
	border: none;
	border-radius: none;
	background: none;
	box-shadow: none;
}
