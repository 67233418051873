/* Application styles */
@import "elements/reset.css";

@import "config/colors.css";
@import "config/sizes.css";
@import "config/typography.css";

@import "elements/base.css";
@import "elements/markdown.css";
@import "elements/animation.css";
@import "syntax-highlighting.css";

@import "components/safe_area.css";
@import "components/grid.css";
@import "components/figure.css";
@import "components/release.css";
@import "components/update.css";
@import "components/star_line.css";
@import "components/pricing_card.css";
@import "components/button.css";
@import "components/input.css";
@import "components/pill.css";
@import "components/toc.css";

@import "home.css";
@import "page.css";
@import "brewer.css";
@import "services.css";
@import "support.css";

@import "utilities.css";

:is(#gradient) {
	position: fixed;
	width: 100dvw;
	height: 100dvh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
}

:where(#banner) {
	position: relative;
	display: block;

	.banner__content {
		padding-block: var(--space-2);
	}
}

:where(#header) {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 20;
	border-block-end: solid 1px var(--color-ink-tertiary);
	backdrop-filter: blur(24px);

	.header__primary {
		display: flex;
		column-gap: var(--space-16);
		align-items: center;
		justify-content: space-between;
		padding-block: var(--space-4);

		> nav {
			display: flex;
			column-gap: var(--fluid-space-8);
			align-items: center;

			@media screen and (max-width: 768px) {
				display: none;
			}
		}
	}

	:where(#secondary_header) {
		border-top: solid 1px var(--color-ink-tertiary);
		padding-block: var(--space-3);
		overflow-x: scroll;
		overflow-y: clip;

		.safe-area, .layout-margins {
			min-width: max-content;
		}

		nav {
			display: flex;
			column-gap: var(--fluid-space-16);
			justify-content: space-between;
			align-items: center;
			min-width: max-content;

			.secondary_header__left, .secondary_header__right {
				display: flex;
				column-gap: var(--space-4);
				align-items: center;
			}
			/**/
			/*.secondary_header__right {*/
			/*	@media screen and (max-width: 768px) {*/
			/*		display: none;*/
			/*	}*/
			/*}*/
		}
	}
}

:where(#mobile_header) {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	backdrop-filter: blur(24px);
	z-index: 20;
	display: grid;
	grid-template-rows: max-content;
	height: 100dvh;

	.mobile_header__ghost_header {
		display: flex;
		column-gap: var(--space-16);
		align-items: center;
		justify-content: space-between;
		padding-block: var(--space-4);
	}

	>nav {
		border-block-start: solid 1px var(--color-ink-tertiary);
	}

	.mobile_header__menu {
		display: flex;
		flex-direction: column;
		gap: var(--space-4);
		padding-block: var(--space-8);

		a:has(.pill) {
			display: flex;
			align-items: start;
			gap: var(--space-2);
		}
	}
}

:where(#application) {
	position: relative;
	min-height: 100dvh;
	display: grid;
	grid-template-rows: auto 1fr auto auto;
	grid-template-columns: minmax(0, 1fr);
}

:where(#footer) {
	.grid--footer {
		grid-template-columns: repeat(2, 1fr);
		gap: var(--space-8);

		.grid__content {
			grid-column: span 1;
		}

		@media screen and (min-width: 768px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media screen and (min-width: 1024px) {
			grid-template-columns: repeat(12, 1fr);

			.grid__content {
				grid-column: span 2;
			}
		}
	}

	.footer__content {
		padding-block: var(--space-8);
		border-block: solid 1px var(--color-ink-tertiary);
	}

	.footer__menu {
		h4 {
			margin-block-end: var(--space-4);
		}

		nav {
			display: flex;
			flex-direction: column;
			row-gap: var(--space-1);
			font-size: var(--font-sm);
			line-height: var(--line-height-sm);
		}
	}

	.footer__menu--connect {
		nav {
			flex-direction: row;
			column-gap: var(--space-4);
		}
	}

	.footer__legals {
		margin-block-start: var(--space-4);
		row-gap: var(--space-2);

		>p {
			grid-column: span 4;
			font-size: var(--font-xs);
			line-height: var(--line-height-xs);
			color: var(--color-ink-secondary);
		}
	}
}

:where(#big-logo) {
	position: relative;
	z-index: 10;
	mix-blend-mode: soft-light;

	svg {
		transform: translateY(var(--fluid-space-8));
		will-change: transform;
		/**/
		/*@media screen and (min-width: 768px) {*/
		/*	transform: translateY(var(--space-8));*/
		/*}*/
	}
}
