.release {
	padding-block-end: var(--fluid-space-8);
	margin-block-end: var(--fluid-space-16);
	border-block-end: solid 1px var(--color-ink-tertiary);

	&:last-of-type {
		border-block-end: none;
	}

	.release__header {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
		column-gap: var(--fluid-space-8);

		h2, p {
			margin: 0;
		}

		p {
			font-size: var(--font-sm);
			line-height: var(--font-sm);

			@media screen and (max-width: 768px) {
				font-size: var(--font-xs);
				line-height: var(--font-xs);
			}
		}
	}
}
