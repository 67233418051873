.support {
	:where(#application) {
		min-height: 100dvh;
		display: grid;
		grid-template-rows: auto 1fr auto auto;
		grid-template-columns: minmax(0, 1fr);
	}

	:where(#content) {
		margin-block: var(--fluid-space-48);
	}
}
