:root {
	/* Named color values */
	--lch-black: 0% 0 0;
	--lch-white: 100% 0 0;

	--rgb-blue: 10 132 255;
	--rgb-blue-vibrant: 20 142 255;
	--rgb-brown: 172 142 104;
	--rgb-brown-vibrant: 182 152 114;
	--rgb-cyan: 90 200 245;
	--rgb-cyan-vibrant: 90 205 250;
	--rgb-gray: 152 152 157;
	--rgb-gray-vibrant: 162 162 167;
	--rgb-green: 50 215 75;
	--rgb-green-vibrant: 60 225 85;
	--rgb-indigo: 94 92 230;
	--rgb-indigo-vibrant: 99 97 242;
	--rgb-mint: 102 212 207;
	--rgb-mint-vibrant: 108 224 219;
	--rgb-orange: 255 159 10;
	--rgb-orange-vibrant: 255 169 20;
	--rgb-pink: 255 55 95;
	--rgb-pink-vibrant: 255 65 105;
	--rgb-purple: 191 90 242;
	--rgb-purple-vibrant: 204 101 255;
	--rgb-red: 255 69 58;
	--rgb-red-vibrant: 255 79 68;
	--rgb-teal: 106 196 220;
	--rgb-teal-vibrant: 68 212 250;
	--rgb-yellow: 255 214 10;
	--rgb-yellow-vibrant: 255 224 20;

	--rgb-christmas: 195 41 41;

	/* Abstractions */
	--color-white: oklch(var(--lch-white));
	--color-black: oklch(var(--lch-black));

	--color-ink: oklch(var(--lch-white) / .85);
	--color-ink-secondary: oklch(var(--lch-white) / .55);
	--color-ink-tertiary: oklch(var(--lch-white) / .25);
	--color-ink-quaternary: oklch(var(--lch-white) / .1);
	--color-ink-quinary: oklch(var(--lch-white) / .05);
	--color-ink-inverted: oklch(var(--lch-white) / .85);

	--color-bg: oklch(var(--lch-black) / .85);
	--color-bg-secondary: oklch(var(--lch-black) / .55);
	--color-bg-tertiary: oklch(var(--lch-black) / .25);
	--color-bg-quaternary: oklch(var(--lch-black) / .1);
	--color-bg-quinary: oklch(var(--lch-black) / .05);

	--color-accent: rgb(var(--rgb-blue));
	--color-accent-vibrant: rgb(var(--rgb-blue-vibrant));

	/*--color-menu: oklch(var(--lch-brick));*/
	/*--color-link: var(--color-ink-secondary);*/
}
