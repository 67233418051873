.pricing_card {
	background-color: var(--color-bg-tertiary);
	padding: var(--space-8);
	border: solid 1px var(--color-ink-tertiary);
	border-radius: var(--border-radius-3xl);
	backdrop-filter: blur(24px);
	display: flex;
	flex-direction: column;
	position: relative;
}

.pricing_card__tag {
	position: absolute;
	bottom: 100%;
	right: var(--space-8);
	transform: translateY(50%);
	padding-block-start: var(--space-05);

	font-size: var(--font-xs);
	line-height: var(--line-height-xs);
	font-weight: 500;
	text-align: center;
	padding-inline: var(--space-2);
	padding-block: var(--space-05);
	border: solid 1px var(--color-ink-tertiary);
	border-radius: 9999px;
	background-color: rgb(var(--pricing_card__tag-color));
}

.pricing_card__header {
	display: flex;
	align-items: center;
	column-gap: var(--space-6);

	img {
		width: var(--space-16);
		height: var(--space-16);

		@media screen and (max-width: 768px) {
			width: var(--space-14);
			height: var(--space-14);
		}
	}
}

.pricing_card__price {
	display: flex;
	flex-direction: column;
	gap: var(--space-2);
	align-items: baseline;

	.pricing_card__price__detail {
		display: flex;
		align-items: baseline;
		gap: var(--space-2);

		.currency_symbol, .money-currency-symbol {
			font-size: var(--font-4xl);
			margin-inline-end: var(--space-1);
		}
	}

	.pricing_card__price--loading {
		opacity: 0;
	}

	.pricing_card__price__old_price {
		text-decoration: line-through;
		text-decoration-thickness: 0.5px;
	}

	.pricing_card__price__old_line {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		padding-block: var(--space-2);
		color: rgb(var(--rgb-pink));
	}

	.pricing_card__price__placeholder {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: 100%;
			height: 80%;
			background-color: var(--color-ink-tertiary);
			border-radius: var(--border-radius-lg);
		}
	}
}

.pricing_card__feature_list {
	display: grid;
	grid-template-columns: 1fr;
	gap: var(--space-3);

	&.pricing_card__feature_list--footnote_style {
		gap: 0;
	}
}

.pricing_card__feature {
	display: flex;
	gap: var(--space-2);

	.pricing_card__feature__icon {
		height: var(--space-6);
		display: flex;
		align-items: center;

		svg {
			width: var(--space-4);
			height: var(--space-4);
		}
	}

	.pricing_card__feature__title {
		@media screen and (max-width: 768px) {
			font-size: var(--font-sm);
			line-height: var(--line-height-sm);
		}
	}

	.pricing_card__feature__subtitle {
		font-size: var(--font-sm);
		line-height: var(--line-height-sm);
		color: var(--color-ink-secondary);

		@media screen and (max-width: 768px) {
			font-size: var(--font-xs);
			line-height: var(--line-height-xs);
		}
	}

	&.pricing_card__feature--footnote_style {
		display: flex;
		justify-content: center;
		text-align: center;

		--link-color: var(--color-ink);
		--link-color-hover: var(--color-ink-secondary);
	}
}

.pricing_card__separator {
	width: 100%;
	height: 1px;
	border: none;
	background-color: var(--color-ink-tertiary);
	margin-block: var(--space-4);

	&.pricing_card__separator--invisible {
		background-color: transparent;
	}

	&.pricing_card__separator--half {
		margin-block: var(--space-2);
	}

	&.pricing_card__separator--toc {
		margin-block-start: var(--space-16);
		margin-block-end: var(--space-8);
	}
}
