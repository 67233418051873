:where(#toc) {
	@media screen and (max-width: 1280px) {
		 display: none;
	}

	h4 {
		margin-block-end: var(--space-6);
	}

	> ul {
		padding: 0;
		list-style: none;

		li {
			margin-block: var(--space-3);
		}

		ul {
			padding-inline-start: var(--space-8);
			list-style: none;

			li {
				position: relative;

				&::before {
					content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="oklch(100% 0 0 / .55)" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" /></svg>');;
					width: var(--space-3);
					height: var(--space-3);
					position: absolute;
					right: 100%;
					margin-inline-end: var(--space-2);
				}
			}
		}
	}
}
