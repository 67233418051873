.pill_wrap {
	display: flex;
	align-items: start;
	gap: var(--space-2);
}

.pill {
	font-size: 10px;
	line-height: 13px;
	color: var(--pill-txt-color, var(--color-ink));
	/* transform: translateY(calc(var(--space-05) * -1)); */
	border: solid 1px var(--pill-color, rgb(var(--rgb-blue-vibrant)));
	border-radius: 9999px;
	padding-inline: var(--space-2);
	padding-block: var(--space-05);
}

.pill--purple {
	--pill-color: rgb(var(--rgb-purple-vibrant));
	/* --pill-txt-color: rgb(var(--rgb-purple-vibrant)); */
}
