:root {
	--screen-sm: 640px;
	--screen-md: 768px;
	--screen-lg: 1024px;
	--screen-xl: 1280px;
	--screen-2xl: 1536px;

	--space-05: 0.125rem;
	--space-1: 0.25rem;
	--space-2: 0.5rem;
	--space-3: 0.75rem;
	--space-4: 1rem;
	--space-6: 1.5rem;
	--space-8: 2rem;
	--space-12: 3rem;
	--space-14: 3.5rem;
	--space-16: 4rem;
	--space-24: 6rem;
	--space-32: 8rem;
	--space-36: 9rem;
	--space-48: 12rem;

	--fluid-space-1: clamp(var(--space-05), 0.3125vw + 0rem, var(--space-1));
	--fluid-space-3: clamp(var(--space-1), 1.25vw + -0.25rem, var(--space-3));
	--fluid-space-4: clamp(var(--space-3), 0.625vw + 0.5rem, var(--space-4));
	--fluid-space-8: clamp(var(--space-4), 2.5vw + -0.0rem, var(--space-8));
	--fluid-space-12: clamp(var(--space-6), 3.75vw + -0.0rem, var(--space-12));
	--fluid-space-16: clamp(var(--space-8), 5.0vw + -0.0rem, var(--space-16));
	--fluid-space-24: clamp(var(--space-12), 7.5vw + -0.0rem, var(--space-24));
	--fluid-space-36: clamp(var(--space-24), 7.5vw + 3.0rem, var(--space-36));
	--fluid-space-48: clamp(var(--space-24), 15.0vw + -0.0rem, var(--space-48));

	--border-radius-none: 0px;
	--border-radius-sm: 0.125rem;
	--border-radius: 0.25rem;
	--border-radius-md: 0.375rem;
	--border-radius-lg: 0.5rem;
	--border-radius-xl: 0.75rem;
	--border-radius-2xl: 1rem;
	--border-radius-3xl: 1.5rem;
	--border-radius-full: 9999px;
}
