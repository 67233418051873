html,
body {
	--font-sans: "InterVariable", "Inter", system-ui;
	--font-serif: ui-serif, serif;
	--font-mono: ui-monospace, monospace;

	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: none;
	font-feature-settings: "dlig", "ss07", "ss08", "cv01", "cv02", "cv03", "cv04", "cv05", "cv10";
	background: initial;
	color: var(--color-ink);
	font-family: var(--font-sans);
	line-height: 1.4;
	overflow: unset;
	scroll-behavior: smooth;
	overscroll-behavior: none;
	text-rendering: optimizeLegibility;
	text-size-adjust: none;
	margin: 0;
	text-wrap: pretty;
}

:is(a, button, input[type="submit"]) {
	cursor: pointer;
}

:is(a) {
	text-decoration: none;
	color: var(--link-color, var(--color-ink-secondary));
	transition: color, text-decoration-color, 150ms cubic-bezier(0.4, 0, 0.2, 1);

	@media (any-hover: hover) {
		&:where(:hover, [data-active="true"], .active) {
			color: var(--link-color-hover, var(--color-ink));
		}
	}
}

:is(a, button, input[type="submit"]):disabled {
	cursor: not-allowed;
	color: var(--color-ink-tertiary);
}
