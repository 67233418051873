:root {
	--font-xs: 0.75rem;
	--font-sm: 0.875rem;
	--font-base: 1rem;
	--font-lg: clamp(1rem, 0.3125vw + 0.875rem, 1.125rem);
	--font-xl: clamp(1.125rem, 0.3125vw + 1rem, 1.25rem);
	--font-2xl: clamp(1.25rem, 0.625vw + 1rem, 1.5rem);
	--font-3xl: clamp(1.5rem, 0.9375vw + 1.125rem, 1.875rem);
	--font-4xl: clamp(1.5rem, 1.875vw + 0.75rem, 2.25rem);
	--font-5xl: clamp(2.25rem, 1.875vw + 1.5rem, 3rem);
	--font-6xl: clamp(2.25rem, 3.75vw + 0.75rem, 3.75rem);
	--font-7xl: clamp(2.25rem, 5.625vw + -0.0rem, 4.5rem);

	--line-height-xs: 1rem;
	--line-height-sm: 1.25rem;
	--line-height-base: 1.5rem;
	--line-height-lg: clamp(1.5rem, 0.625vw + 1.25rem, 1.75rem);
	--line-height-xl: 1.75rem;
	--line-height-2xl: clamp(1.75rem, 0.625vw + 1.5rem, 2rem);
	--line-height-3xl: clamp(2rem, 0.625vw + 1.75rem, 2.25rem);
	--line-height-4xl: clamp(2rem, 1.25vw + 1.5rem, 2.5rem);
	--line-height-5xl: clamp(2.5rem, 1.25vw + 2.0rem, 3rem);
	--line-height-6xl: clamp(2.5rem, 3.125vw + 1.25rem, 3.75rem);
	--line-height-7xl: clamp(2.5rem, 5.0vw + 0.5rem, 4.5rem);
}
