@import url("_normalize.css");

:root {
	line-height: 1.5;
}

h1, h2, h3, h4, h5, figure, p, ol, ul {
	margin: 0;
}

ol[role="list"], ul[role="list"] {
	list-style: none;
	padding-inline: 0;
}

h1, h2, h3, h4, h5 {
	font-size: inherit;
	font-weight: inherit;
}

img {
	display: block;
	max-inline-size: 100%;
}
