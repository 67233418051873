.figure {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-block: var(--fluid-space-16);
	user-select: none;

	figure {
		margin-inline: calc(-1 * var(--space-12));

		img, video {
			display: block;
			max-inline-size: 100%;
			cursor: zoom-in;
			outline-offset: var(--space-1);
		}

		.figure__video_wrapper {
			border-radius: var(--border-radius-lg);
			overflow: clip;
			will-change: transform;
		}

		figcaption {
			color: var(--color-ink-secondary);
			text-align: center;
			margin: var(--fluid-space-4);
			margin-block-end: 0;
		}

		@media screen and (max-width: 1536px) {
			margin-inline: 0;

			figcaption {
				font-size: var(--font-sm);
				line-height: var(--line-height-sm);
			}
		}
	}
}

.figure--zoomed {
	position: fixed;
	background-color: var(--color-bg);
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	margin: 0;
	z-index: 50;

	figure {
		margin-inline: var(--fluid-space-16);
		max-width: var(--screen-xl);

		img, video {
			cursor: zoom-out;
		}

		figcaption {
			font-size: var(--font-lg);
			line-height: var(--line-height-lg);
		}

		@media screen and (max-width: 768px) {
			margin-inline: var(--space-2);
		}
	}
}
